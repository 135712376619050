<template>
  <div class="blog-wrapper">
    <div class="blog-wrap">
      <div class="blog-title wow animate__fadeInLeft">Berita</div>
      <div class="blog-item-box">
        <el-row :gutter="50" class="blog-row">
          <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
            <div class="blog-video-item wow animate__fadeInLeft">
              <iframe class="video-iframe" src="https://www.youtube.com/embed/3tvrxTkTuxE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
            <div class="blog-text-item wow animate__fadeInRight">
              <div class="blog-text-title">Bantu Indonesia dengan Vaksin</div>
              <div class="blog-text-content">BantuSaku membantu pemerintah Indonesia meningkatkan angka presentasi Vaksin Covid-19</div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="70" class="blog-row">
          <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
            <div class="blog-video-item wow animate__fadeInLeft">
              <iframe class="video-iframe" src="https://www.youtube.com/embed/n1kjV4u4VNE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
            <div class="blog-text-item wow animate__fadeInRight">
              <div class="blog-text-title">Program CSR BisaBantu</div>
              <div class="blog-text-content">BantuSaku menyerahkan bantuan sembako guna untuk menunjang kebutuhan sehari-hari</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from 'wowjs'
export default {
  data() {
    return {
    };
  },
  mounted(){
    this.$nextTick(() => {
      var wow = new WOW({
        animateClass: 'animated',
        offset: 0,
      })
      wow.init()
    })
    sessionStorage.setItem('activeTab', '5')
  },
}
</script>

<style lang="scss" scoped>
.blog-wrapper{
  width: 100%;
  background: #C6E8ED;
  padding-top: 80px;
  box-sizing: border-box;
  overflow: hidden;
  .blog-wrap{
    max-width: 1400px;
    margin: 0 auto;
    .blog-title{
      font-size: 60px;
      font-weight: 600;
      margin: 0 0 40px 15px;
    }
    .blog-item-box{
      .blog-row{
        margin-bottom: 108px;
      }
      .blog-video-item{
        width: 100%;
        .video-iframe{
          width: 100% !important;
          aspect-ratio: 16/9;
        }
      }
      .blog-text-item{
        padding: 26px 0 0 0;
        box-sizing: border-box;
        .blog-text-title{
          font-size: 44px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .blog-text-content{
          font-size: 22px;
        }
      }
    }
  }
}
</style>